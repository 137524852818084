import type { BulletPointsType } from '@/services/bullet-points';

import BulletPointHighlightSpan from './BulletPointHighlightSpan';

const HighlightText = ({ bullet }: { bullet: BulletPointsType }) => {
  const valuesToHighlight = bullet.ratings
    .map((e) => e)
    .filter((e) => e.text !== '' && ' ');

  const escapeRegExp = (text: string) =>
    text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(
    `(${valuesToHighlight.map((e) => escapeRegExp(e.text)).join('|')})`,
    'g'
  );
  const parts = bullet.text.split(regex).filter((part) => part?.trim() !== '');

  const findValueToHighlight = (part: string) =>
    valuesToHighlight.find((value) => value.text === part);

  return (
    <>
      {parts.map((part) =>
        findValueToHighlight(part) ? (
          <BulletPointHighlightSpan
            key={part}
            bulletPointsCategory={findValueToHighlight(part)!.category}
          >
            {part}
          </BulletPointHighlightSpan>
        ) : (
          part
        )
      )}
    </>
  );
};

export default HighlightText;
