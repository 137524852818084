import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '@/components/Button';
import { useProfile } from '@/hooks/useProfile';
import { EnumAccountType } from '@/services/auth/auth';

const recruiterPages = ['/recruiter', '/resume', '/job-matching'];

const ConditionalActionButton = () => {
  const { data: profileData } = useProfile();
  const { pathname } = useRouter();
  const isRecruiterPage = recruiterPages.includes(pathname);

  const getGoToAppButtonLabel = () => {
    if (
      profileData?.accountType === EnumAccountType.CUSTOMER ||
      profileData?.accountType === 'ADMIN'
    )
      return 'Go to App';

    if (profileData?.accountType === EnumAccountType.CANDIDATE)
      return 'Go to Jobs';

    return 'Try for free';
  };

  const getGoToAppButtonHref = () => {
    if (
      profileData?.accountType === EnumAccountType.CUSTOMER ||
      profileData?.accountType === 'ADMIN'
    )
      return '/dashboard';
    if (profileData?.accountType === EnumAccountType.CANDIDATE) return '/jobs';
    if (isRecruiterPage) return '/dashboard/auth/signin';
    return '/jobs';
  };

  return (
    <Link href={getGoToAppButtonHref()} className="w-full">
      <Button
        variant="jbGradient"
        className="w-full text-neutral-0"
        tabIndex={-1}
      >
        {getGoToAppButtonLabel()}
      </Button>
    </Link>
  );
};

export default ConditionalActionButton;
