import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import Script from 'next/script';
import { type ReactElement, useRef } from 'react';
import Marquee from 'react-fast-marquee';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import Apple from '@/assets/companies/apple.svg';
import AWS from '@/assets/companies/aws.svg';
import Google from '@/assets/companies/google.svg';
import Intel from '@/assets/companies/intel.svg';
import Meta from '@/assets/companies/meta.svg';
import Netflix from '@/assets/companies/netflix.svg';
import NVIDIA from '@/assets/companies/nvidia.svg';
import Tesla from '@/assets/companies/tesla.svg';
import BlueArrow from '@/assets/jb-bullet-arrows/blue.webp';
import ArrowDown from '@/assets/jb-bullet-arrows/down.webp';
import GreenArrow from '@/assets/jb-bullet-arrows/green.webp';
import OrangeArrow from '@/assets/jb-bullet-arrows/orange.webp';
import RedArrow from '@/assets/jb-bullet-arrows/red.webp';
import MacbookPro from '@/assets/MacbookPro.webp';
import Rocket from '@/assets/rocket.webp';
import BulletPointHighlightSpan from '@/components/BulletPoints/BulletPointHighlightSpan';
import BulletPointsGeneratorFromText from '@/components/BulletPoints/BulletPointsGeneratorFromText';
import BulletsTitle from '@/components/BulletPoints/BulletsTitle';
import Button from '@/components/Button';
import MultiLineInput from '@/components/Form/Fields/MultiLineInput';
import TextInputField from '@/components/Form/Fields/TextInputField';
import Layout from '@/components/Landing/Layout';
import ScrollAnimatedText from '@/components/ScrollAnimatedText';
import Text from '@/components/Text';
import { BulletPointsCategory } from '@/services/bullet-points';
import { contactUs } from '@/services/common';

const MindPalCo = () => {
  const rocketContainer = useRef(null);
  const containerRef = useRef(null);

  const { scrollYProgress: heroSectionYProgress } = useScroll({
    target: rocketContainer,
  });
  const scrollYSpringHeroSection = useSpring(heroSectionYProgress, {
    stiffness: 50,
    damping: 20,
    restDelta: 0.9,
  });
  const rocketScale = useTransform(scrollYSpringHeroSection, [0, 1], [3, 1]);
  const rocketTransformY = useTransform(
    scrollYSpringHeroSection,
    [0, 1],
    ['100%', '30%']
  );
  const titleTransformY = useTransform(
    scrollYSpringHeroSection,
    [0, 1],
    ['0%', '10%']
  );
  const companiesLogoTransformY = useTransform(
    scrollYSpringHeroSection,
    [0, 1],
    ['0%', '-30%']
  );
  const companiesLogoScale = useTransform(
    scrollYSpringHeroSection,
    [0, 1],
    [1, 0.2]
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: { email: '', message: '' },
    resolver: yupResolver(schema),
  });
  const { mutate } = useMutation({
    mutationFn: contactUs,
    mutationKey: ['contactUs'],
    onSuccess: () => {
      reset();
      toast.success('Message sent successfully');
    },
  });
  const companies = [
    { name: 'Apple', logo: <Apple />, position: 'left-[10%] bottom-[90%]' },
    { name: 'AWS', logo: <AWS />, position: 'left-[16%] bottom-[60%]' },
    { name: 'Google', logo: <Google />, position: 'left-[30%] bottom-[46%]' },
    { name: 'Intel', logo: <Intel />, position: 'left-[2%] bottom-[50%]' },
    { name: 'Meta', logo: <Meta />, position: 'right-[10%] bottom-[90%]' },
    {
      name: 'Netflix',
      logo: <Netflix />,
      position: 'right-[16%] bottom-[60%]',
    },
    { name: 'NVIDIA', logo: <NVIDIA />, position: 'right-[30%] bottom-[46%]' },
    { name: 'Tesla', logo: <Tesla />, position: 'right-[2%] bottom-[50%]' },
  ];

  const xScrollText = ['Search.', 'Apply.', 'Advance.'];

  return (
    <>
      <Script id="google-ads-conversion" strategy="afterInteractive">
        {`
            gtag('event', 'conversion', { 'send_to': 'AW-11550243012/9bdeCMKqtYIaEMTxyoMr', 'value': 1.0, 'currency': 'PLN' });
            `}
      </Script>
      <div className="flex w-full flex-col" ref={containerRef}>
        <div className="relative h-[200vh] w-full" ref={rocketContainer}>
          <div className="sticky left-0 top-0 flex h-screen w-full items-center justify-center overflow-hidden bg-candidate-landing-background">
            <motion.div
              className="relative z-10 flex flex-col items-center justify-center gap-20"
              style={{ y: titleTransformY }}
            >
              <div className=" flex flex-col items-center justify-center">
                <Text variant="display-3" className="text-center md:text-left">
                  We are introducing
                </Text>
                <Text variant="display-3" className="text-center md:text-left">
                  MindPal <strong className="text-primary-400">Jobs</strong> for
                  Candidates
                </Text>
              </div>
              <Text className="text-center md:text-left" variant="h3">
                Just Search. Apply. Advance.
              </Text>
              <Link href="/jobs" target="_blank">
                <Button
                  variant="jbGradient"
                  suffixIcon="material-symbols-light:open-in-new"
                  size="long"
                >
                  Try it now
                </Button>
              </Link>
            </motion.div>
            <motion.div
              style={{ y: companiesLogoTransformY }}
              className=" absolute bottom-0 h-1/2 w-full max-w-7xl px-14"
            >
              {companies.map(({ name, logo, position }) => (
                <motion.div
                  key={name}
                  style={{ scale: companiesLogoScale }}
                  className={classNames(
                    'absolute bg-neutral-100 rounded-full md:w-14 w-7 aspect-square flex justify-center items-center',
                    position
                  )}
                >
                  <div className="flex aspect-square h-3/5 items-center justify-center">
                    <div style={{ width: '100%', height: 'auto' }}>{logo}</div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
            <motion.div
              style={{ top: rocketTransformY }}
              className="absolute left-0 z-0 flex h-screen w-full items-start justify-center overflow-hidden"
            >
              <motion.div
                style={{ scale: rocketScale }}
                className="orig absolute left-0 top-0 z-0 flex h-full w-full origin-top items-start justify-center"
              >
                <Image src={Rocket} alt="rocket" className="min-w-[1300px]" />
              </motion.div>
            </motion.div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center bg-neutral-200 p-4 md:py-20">
          <div className="flex w-full flex-col items-center justify-center gap-10 px-6 md:max-w-lg lg:max-w-2xl">
            <BulletsTitle />
            <div className="flex w-full flex-col justify-start gap-2">
              <Text className="font-semibold text-jb-neutral-700">
                Paste your job experience here{' '}
              </Text>
              <div className="w-full rounded-xl border border-neutral-300 p-4 shadow-md">
                <Text variant="jb-body-small" className="text-jb-neutral-700">
                  During an intensive fellowship, I had the opportunity to
                  develop three AI projects. These projects aimed to contribute
                  to an open-source community and earn an accepted pull request.
                  Throughout the fellowship, I focused on various aspects,
                  including vision-building, interview preparation, networking,
                  and project development. Industry professionals provided
                  guidance and feedback, which helped me enhance both my
                  technical skills and professional growth.
                </Text>
              </div>
            </div>
            <Image src={ArrowDown} alt="" className="w-5" />
            <div>
              <Text className="font-semibold text-jb-neutral-1000">
                Bullet Point Result{' '}
              </Text>
              <ul className="relative flex w-full list-disc flex-col gap-4 rounded-xl border border-jb-primary-400 p-4 pl-8 marker:text-h4 marker:text-primary-600">
                <li>
                  <Text className="leading-8 text-jb-neutral-900">
                    <BulletPointHighlightSpan
                      absoluteImageClassName="bottom-1/2 right-full w-52"
                      bulletPointsCategory={BulletPointsCategory.ACTION_VERB}
                      staticImage={RedArrow}
                    >
                      Developed
                    </BulletPointHighlightSpan>
                    <BulletPointHighlightSpan
                      absoluteImageClassName="-top-4 right-1/2 w-80"
                      bulletPointsCategory={BulletPointsCategory.METRIC}
                      staticImage={OrangeArrow}
                    >
                      3 AI projects
                    </BulletPointHighlightSpan>{' '}
                    as{' '}
                    <BulletPointHighlightSpan
                      bulletPointsCategory={BulletPointsCategory.STRATEGY}
                    >
                      part of an intensive fellowship, aimed at contributing to
                      an open-source community
                    </BulletPointHighlightSpan>{' '}
                    and{' '}
                    <BulletPointHighlightSpan
                      bulletPointsCategory={BulletPointsCategory.OUTCOME}
                    >
                      earning an accepted pull request (PR).
                    </BulletPointHighlightSpan>
                  </Text>
                  <Image
                    src={GreenArrow}
                    alt=""
                    className="absolute hidden w-96 max-w-none md:bottom-16 md:left-[89%] md:flex lg:bottom-8 lg:left-[95%]"
                  />
                  <Image
                    src={BlueArrow}
                    alt=""
                    className="absolute hidden w-52 max-w-none md:bottom-[89%] md:left-[88%] md:flex lg:bottom-52 lg:left-[90%]"
                  />
                </li>
                <li>
                  <Text className="leading-8 text-jb-neutral-900">
                    Focusing on vision-building, interview preparation,
                    networking, and project development, with guidance and
                    feedback from industry professionals, to enhance both
                    technical skills and professional growth
                  </Text>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center bg-neutral-200 py-10">
          <BulletPointsGeneratorFromText />
        </div>
        <div className="bg-neutral-100">
          <ScrollAnimatedText containerRef={containerRef} className="py-10">
            {xScrollText.map((text) => (
              <Text key={text} className="text-neutral-300" variant="display-1">
                {text}
              </Text>
            ))}
          </ScrollAnimatedText>
          <div className="flex items-center justify-center bg-neutral-100">
            <div className="flex w-full max-w-7xl flex-col gap-28 p-10 md:flex-row">
              <div className="flex flex-2 flex-col items-start justify-center gap-10 text-neutral-1000">
                <Text variant="h3">From recruiters to candidates</Text>
                <Text>
                  Tired? Resigned? Or maybe ambitious and wanting more? We know
                  that job searching is not an easy task. Give yourself a try.
                </Text>
                <Link href="/jobs" target="_blank">
                  <Button
                    variant="jbGradientBorder"
                    suffixIcon="material-symbols-light:open-in-new"
                    size="long"
                    tabIndex={-1}
                  >
                    Explore this product
                  </Button>
                </Link>
              </div>
              <div className="flex-4">
                <Image src={MacbookPro} alt="MacbookPro" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-neutral-200 py-20">
          <Text variant="h4" className="text-center text-neutral-1000">
            Explore Career Opportunities from Top Companies
          </Text>
          <Marquee autoFill className="py-10" pauseOnHover>
            {companies.map(({ name, logo }) => (
              <div
                key={name}
                className="mx-5 flex aspect-square w-32 flex-col items-center justify-center gap-4 rounded-lg bg-neutral-100 shadow-lg hover:cursor-pointer hover:bg-candidate-gradient-border"
              >
                <div className="flex aspect-square h-12 items-center justify-center">
                  <div style={{ width: '100%', height: 'auto' }}>{logo}</div>
                </div>
                <Text variant="body-caption" className="text-neutral-1000">
                  {name}
                </Text>
              </div>
            ))}
          </Marquee>
        </div>
        <div className=" flex items-center justify-center bg-neutral-100">
          <div className="flex w-full max-w-7xl flex-col gap-28 p-10 md:flex-row">
            <div className="flex flex-1 flex-col items-start justify-center gap-10 text-neutral-1000">
              <Text variant="h2">
                As a Startup full of passionate people,{' '}
                <span className="text-primary-600">we value feedback</span>{' '}
              </Text>
              <Text>
                Fell free to contact us and share your comments and suggestions.
              </Text>
              <Text>
                We can also arrange an online chat, and in return we will help
                you upgrade your resume. Or help you in other way.
              </Text>
            </div>
            <form
              className="flex w-full flex-1 flex-col gap-6 rounded-3xl border-2 p-6 md:p-10"
              onSubmit={handleSubmit((formData) => mutate(formData))}
            >
              <Text className="text-neutral-1000" variant="h2">
                Contact us
              </Text>
              <TextInputField
                {...register('email')}
                placeholder="john.doe@example.com"
                variant="light"
                error={errors.email?.message}
              />
              <MultiLineInput
                {...register('message')}
                placeholder="Your message"
                variant="light"
                error={errors.message?.message}
              />
              <Button variant="primaryLight" className="w-full" type="submit">
                Send message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default MindPalCo;

MindPalCo.getLayout = (page: ReactElement) => <Layout>{page}</Layout>;

const schema = object({
  email: string().email('Invalid email').required('Email is required'),
  message: string().required('Message is required'),
});
