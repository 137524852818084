import type { FC, PropsWithChildren } from 'react';

import Footer from './Footer';
import Header from './Header/Header';

type Props = {
  theme?: 'dark' | 'light';
};

const Layout: FC<PropsWithChildren<Props>> = ({ children, theme = 'dark' }) => {
  return (
    <>
      <Header theme={theme} />
      <div className="flex flex-1 flex-col">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
