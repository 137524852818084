import type {
  BulletPointsCategory,
  BulletPointsType,
} from '@/services/bullet-points';

export const calculateCategoryRateSum = (
  initialValue: number | null,
  bulletPoint: BulletPointsType,
  category: BulletPointsCategory
) => {
  const effectiveCategorySum = initialValue ?? 0;
  const categoryRates = bulletPoint.ratings
    .filter((rate) => rate.category === category)
    .map((rate) => rate.rate);

  const categoryRatesSums = categoryRates.reduce((acc, rate) => acc + rate, 0);

  return effectiveCategorySum + categoryRatesSums;
};
