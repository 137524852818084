import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

import type { BulletPointsType } from '@/services/bullet-points';

import LoadingPlaceholder from '../LoadingPlaceholder';
import Text from '../Text';
import HighlightText from './HighlightText';

type Props = {
  bulletPointsList?: BulletPointsType[];
  className?: string;
  isLoading?: boolean;
};
const BulletPointsList: FC<PropsWithChildren<Props>> = ({
  bulletPointsList = [],
  className,
  isLoading,
  children,
}) => {
  const isBulletPointsGenerated = !isLoading && bulletPointsList.length > 0;
  const renderContent = () => {
    if (isBulletPointsGenerated)
      return (
        <ul className="marker:text-jb-neutral-800' flex w-full list-disc flex-col gap-6 p-2 pl-4 marker:text-h4 md:!p-6 md:!pl-10">
          {bulletPointsList.map((bullet) => (
            <li className="text-jb-neutral-1000" key={bullet.id}>
              <Text
                variant="jb-body-large"
                className="whitespace-pre-wrap !leading-8"
              >
                <HighlightText bullet={bullet} />
              </Text>
            </li>
          ))}
        </ul>
      );
    if (isLoading)
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="flex flex-col gap-2">
            <LoadingPlaceholder />
            <Text variant="jb-body-medium" className="text-jb-neutral-700">
              This may take a while...
            </Text>
          </div>
        </div>
      );
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Text variant="jb-body-large" className="text-jb-neutral-700">
          No bullet points generated
        </Text>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'overflow-auto flex-1 h-full flex flex-col',
        className
      )}
    >
      <div className="flex w-full flex-1 overflow-auto">{renderContent()}</div>
      <div className="w-full py-2">{children}</div>
    </div>
  );
};
export default BulletPointsList;
