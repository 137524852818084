import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const useWritingAnimation = (text: string, interval: number) => {
  const [index, setIndex] = useState(1);
  const { ref, inView } = useInView();
  useEffect(() => {
    if (!inView) return;
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex >= text.length) clearInterval(intervalId);
        return prevIndex + 1;
      });
    }, interval);
    return () => clearInterval(intervalId);
  }, [inView]);

  return { ref, animatedText: text.slice(0, index) };
};
