import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { object, string } from 'yup';

import CurvedArrow from '@/assets/drawing-illustrations/CurvedArrow.svg';
import FlowingArc from '@/assets/drawing-illustrations/FlowingArc.svg';
import RadiatingLinesLeft from '@/assets/drawing-illustrations/RadiatingLinesLeft.svg';
import RadiatingLinesTop from '@/assets/drawing-illustrations/RadiatingLinesTop.svg';
import DrawnUnderline from '@/assets/DrawnUnderline.webp';
import { useWritingAnimation } from '@/hooks/useWritingAnimation';
import { queries } from '@/queries';
import { generateBulletPointsFromText } from '@/services/bullet-points';

import Button from '../Button';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import Text from '../Text';
import BulletPointsList from './BulletPointsList';
import BulletPointsScoreOverview from './BulletPointsScoreOverview';
import BulletPointsTooltip from './BulletPointsTooltip';

const BulletPointsGeneratorFromText = () => {
  const { ref, animatedText } = useWritingAnimation(
    'During an intensive fellowship, I had the opportunity to develop three AI projects. These projects aimed to contribute to an open-source community and earn an accepted pull request. Throughout the fellowship, I focused on various aspects, including vision-building, interview preparation, networking, and project development. Industry professionals provided guidance and feedback, which helped me enhance both my technical skills and professional growth.',
    40
  );
  const {
    mutate,
    data,
    reset: resetMutation,
  } = useMutation({
    mutationFn: generateBulletPointsFromText,
    mutationKey: ['bullet-points'],
  });

  const currentBulletPointsId = data?.id;

  const { data: bulletPointsData } = useQuery({
    ...queries.bulletPoints.detail(currentBulletPointsId),
    enabled: !!currentBulletPointsId,
    refetchInterval: (queryData) =>
      !queryData?.isGenerated ? 1000 * 5 : false,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { text: '' },
    resolver: yupResolver(schema),
  });

  const formatToCopyToClipboard = bulletPointsData?.generatedBulletPoints
    .map(({ text }) => `• ${text}`)
    .join('\n\n');

  const copyToClipboard = () => {
    if (!formatToCopyToClipboard)
      return toast.error('No bullet points to copy');
    navigator.clipboard.writeText(formatToCopyToClipboard);
    toast.success('Bullet points copied to clipboard!');
  };

  return (
    <div className="relative flex w-full max-w-7xl flex-col items-center gap-6 px-6 text-jb-neutral-1000 md:px-28 md:py-16">
      <SvgDecorations />
      <div className="w-full max-w-md px-6">
        <Text variant="h3" className="text-center font-semibold">
          <span className="text-jb-primary-400">Mind</span> Your Words Make
          Every <span className="text-jb-primary-400">Bullet</span> Count
        </Text>
        <Image src={DrawnUnderline} alt="" />
        <div className="mt-2  flex w-full flex-row items-center justify-center gap-2">
          <Text className="text-center">
            Transform Your Text and{' '}
            <span className="text-jb-primary-400">Make Your Point</span>
          </Text>
          <Icon icon="gravity-ui:hand-ok" />
        </div>
      </div>
      <div className="flex w-full flex-col gap-5 md:flex-row" ref={ref}>
        <form
          onSubmit={handleSubmit((formData) => mutate(formData.text))}
          className="flex w-full flex-3 flex-col gap-2"
        >
          {!currentBulletPointsId && (
            <MultiLineInput
              {...register('text')}
              className="w-full flex-1 shadow-md [&:not(:focus-within)]:border-transparent"
              variant="light"
              characterLimitPosition="inside"
              rows={14}
              characterLimit={1000}
              placeholder={animatedText}
              error={errors.text?.message}
            />
          )}
          {currentBulletPointsId && (
            <BulletPointsList
              bulletPointsList={bulletPointsData?.generatedBulletPoints}
              isLoading={!bulletPointsData?.isGenerated}
              className="h-96 max-h-96 rounded-lg bg-jb-neutral-100 px-6 text-jb-neutral-1000 shadow-md"
            />
          )}
          <div className="flex flex-row gap-2">
            <Button
              prefixIcon="iconamoon:trash"
              size="candidate-sm"
              variant="candidate-white"
              className="text-jb-neutral-800"
              onClick={() => {
                reset();
                resetMutation();
              }}
              confirm="Are you sure you want to clear the bullet points?"
            >
              Clear
            </Button>
            {formatToCopyToClipboard && (
              <Button
                prefixIcon="material-symbols:content-copy-outline"
                size="candidate-sm"
                variant="candidate-white"
                className="text-jb-neutral-800"
                onClick={() => copyToClipboard()}
              >
                Copy
              </Button>
            )}
          </div>
          <div className="flex flex-row items-center justify-end rounded-lg bg-jb-neutral-100 p-3 shadow-md">
            <div className="flex flex-row items-center gap-4">
              <BulletPointsTooltip>
                <div className="flex flex-col gap-2">
                  <Text className="font-semibold" variant="jb-body-small">
                    Trial Limit
                  </Text>
                  <Text variant="jb-body-small">
                    You <strong>have 3 free trials</strong> per hour to craft a
                    bullet point. Want unlimited access? <strong>Log In</strong>{' '}
                    or <strong>Sign Up</strong> to keep creating without limits.
                  </Text>
                </div>
              </BulletPointsTooltip>
              <div className="relative">
                <div className="absolute right-10 top-14 hidden w-40 md:flex">
                  <CurvedArrow />
                </div>
                <Button
                  type="submit"
                  variant="jbPrimary"
                  className="px-6 text-neutral-100"
                  size="sm"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </form>
        <BulletPointsScoreOverview
          className="flex-1 rounded-lg bg-jb-neutral-100 shadow-md"
          bulletPointsList={bulletPointsData?.generatedBulletPoints ?? []}
        />
      </div>
    </div>
  );
};

export default BulletPointsGeneratorFromText;

const schema = object({
  text: string()
    .min(50, 'This field must contain at least 50 characters')
    .max(1000, 'This field must contain at most 1000 characters')
    .required(),
});

const SvgDecorations = () => {
  const illustrations = [
    { className: 'top-40 right-0 w-40', graphic: <FlowingArc />, key: 1 },
    {
      className: 'top-40 left-10 w-20',
      graphic: <RadiatingLinesLeft />,
      key: 2,
    },
    {
      className: 'top-0 left-1/3 w-20',
      graphic: <RadiatingLinesTop />,
      key: 3,
    },
  ];
  return (
    <>
      {illustrations.map(({ className, graphic, key }) => (
        <div
          className={classNames('absolute md:flex hidden', className)}
          key={key}
        >
          {graphic}
        </div>
      ))}
    </>
  );
};
