import classNames from 'classnames';
import { Fragment } from 'react';

import Text from '../Text';

const BulletsTitle = () => {
  const bulletPointsAdvantages = [
    { name: 'Action Verb', className: 'bg-jb-highlight-pink' },
    { name: 'Strategy', className: 'bg-jb-highlight-blue' },
    { name: 'Outcome', className: 'bg-jb-highlight-green' },
    { name: 'Metric', className: 'bg-jb-highlight-orange' },
  ];

  return (
    <div className="flex flex-col items-center gap-4">
      <Text
        variant="h3"
        className="text-center font-semibold text-jb-neutral-1000"
      >
        Power Bullet Formula
      </Text>
      <div className="grid grid-cols-2 flex-row items-center gap-2 md:flex">
        {bulletPointsAdvantages.map(({ name, className }, index) => (
          <Fragment key={name}>
            <Text
              className={classNames(
                'bg-contain font-semibold text-jb-neutral-1000 text-center bg-center bg-no-repeat p-5',
                className
              )}
            >
              {name}
            </Text>
            {index !== bulletPointsAdvantages.length - 1 && (
              <Text
                variant="h4"
                className="hidden text-center text-jb-neutral-1000 md:flex"
              >
                +
              </Text>
            )}
          </Fragment>
        ))}
      </div>
      <Text className="text-center text-neutral-700" variant="jb-body-small">
        Bullet points make your achievements clear, measurable, and impactful -
        perfect for catching a recruiter’s eye.
      </Text>
    </div>
  );
};

export default BulletsTitle;
